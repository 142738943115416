
import { Mixin } from "@/core/mixins/mixin";
import { ProjectDtos } from "@/shims-videos";
import { Component, Mixins, Prop } from "vue-property-decorator";
import { Row, Col, Button, Icon, Image, Popup } from "vant";
@Component({
  components: {
    Mixin,
    [Row.name]: Row,
    [Col.name]: Col,
    [Button.name]: Button,
    [Icon.name]: Icon,
    [Image.name]: Image,
    [Popup.name]: Popup,
  },
})
export default class salePolicy extends Mixins(Mixin) {
  @Prop({
    default: () => {
      return {};
    },
    type: Object,
  })
  public preferential!: any;
  @Prop({
    default: () => {
      return [];
    },
    type: Array,
  })
  public couponsList!: Array<good.SimpleCouponDto>;

  public showPreferential = false;
  get preferentialList() {
    let arr = [
      { key: "coupon", isShow: false, value: [] as any },
      { key: "cultureCouponId", isShow: true, value: [] as any },
      { key: "memberDistription", isShow: true, value: [] as any },
      { key: "packageTicket", isShow: true, value: [] as any },
      { key: "discountTicket", isShow: true, value: [] as any },
      { key: "giftTicket", isShow: true, value: [] as any },
    ];
    arr.forEach((item) => {
      let originItem = this.preferential[item.key];
      item.value = originItem;
      item.isShow = originItem && originItem.length > 0;
    });
    if (this.couponsList && this.couponsList.length > 0) {
      arr[0].isShow = true;
      arr[0].value = this.couponsList;
    }
    return arr;
  }
}
